/* eslint-disable*/

import React, { useState } from "react";
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";
import colab from "../../assets/colab.png";
import input from "../../assets/input.png";
import video_icon from "../../assets/video_icon.png";
import stationF from "../../assets/station-f-logo.png";
import microsoftaicoinnovation from "../../assets/microsoftaicoinnovation.png";
import incubateurHec from "../../assets/LogoincubateurHECPariswhite.png";
import exampleVideo from "../../assets/example_video_new.mp4";
import githubLogoWhite from "../../assets/GitHub_Logo_White.png";
import "./header.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from '@mui/material/TextField';
import DialogTitle from "@mui/material/DialogTitle";
import Alert from '@mui/material/Alert'
import axios from "axios";
import { saveAs } from 'file-saver'
import WebCam from "./WebCam";
import WebPicture  from "./WebPicture";
import { Progress } from 'antd';
import getBlobDuration from 'get-blob-duration'
import 'antd/dist/antd.css'
import { ClickAwayListener } from "@mui/material";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { globalStateContext } from "../../App";
import { Highlight, Basic, themes } from "prism-react-renderer"
import Xarrow, {useXarrow, Xwrapper} from 'react-xarrows';
import { HashLink } from 'react-router-hash-link';



const Header = ({WebsiteMode}) => {
  const updateXarrow = useXarrow();
  const [video, setVideo] = useState();
  const [email, setEmail] = useState();
  const [videoText, setVideoText] = useState();
  const [image, setImage] = useState();
  const [audioURL, setAudioURL] = useState();
  const [audioDuration, setAudioDuration] = useState();
  const [response, setResponse]  = useState(false)
  const [download, setDownload]  = useState(false)
  const [error, setError]  = useState(false)
  const [downloadLink, setDownloadLink] = useState('')
  
  const[paymentNeeded , setPaymentNeeded] = useState(false)
  const urlString = window.location.href
  const hasPayed = urlString.includes("B2F88bc46D42f5B3c95e7ec62E2a2a4aDa00ac9DB4f4D10616")
  
  const getVideoDuration = (file, next) =>
  new Promise((resolve, reject) => {
    console.log(file)
    const reader = new FileReader();
    var alreadymadeADuration = false
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.addEventListener("durationchange", function (e) {
        if (this.duration!=Infinity && !alreadymadeADuration) {
          alreadymadeADuration=true
           var duration = this.duration
           console.log(duration)
           media.remove();
           next(duration);
        };
      }, false);      
      media.load();
      media.currentTime = 24*60*60; //fake big time
      media.volume = 0;
      media.play();
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });




  const formData = new FormData();
  const onInputVideoTextChange = async(e) =>{
    e.preventDefault();
    if (e.target.value.length <=250){
      setPaymentNeeded(false)
    } else if (!hasPayed) {
      setPaymentNeeded(true)
    }
    await setVideoText(e.target.value);
  }
  const onInputChange1 = async(e) =>{
    e.preventDefault();
    await setEmail(e.target.value);
  }
  const onInputChange = async (e) => {
  e.preventDefault();
  setPaymentNeeded(false)

  console.log("Upload: " + e.target.files[0])
  if (e.target.files[0] != undefined){
    console.log("Upload: " + e.target.files[0])
    
   setVideo(e.target.files[0]);  
   setAudioURL(URL.createObjectURL(e.target.files[0]))
    getVideoDuration(e.target.files[0], function (durationValue)  {
      setAudioDuration(durationValue | 0)
    })}
    };



const[progress , setProgress ] = useState(0) 
  function wait(){
    let i =0;
    if(i<=100){
      setInterval(() => {
        if(i>=100){
          i =0;
        }
        i = i+1;
        setProgress(()=>i)        
      }, 1000);
    }
   
    
    }
  

  const handleUpload =   (event) => {
   event.preventDefault();
   if(video){
      formData.append('audio', video)
   } else {
    formData.append('text', videoText.replace('"', '\''))
   }
   formData.append('email', email)
   formData.append('width', 512)
   formData.append('height', 320)
    let data = 'yes';
    for (var value of formData.values()) {
        if(value === 'undefined'){
          data = 'no';          
        }
   }
   if( data ==='no'){
     setOpen1(true)
   }
   else{
    if(video){
    getVideoDuration(video, function (durationValue)  {
      setAudioDuration(durationValue | 0)
     console.log("dddddd: ", durationValue)
    if(durationValue > 15.0 && !hasPayed){
      console.log("duran: ", durationValue)
      setPaymentNeeded(true)
      console.log("durdsdsan: ", durationValue)
    }
    else{
    //setResponse(true)
    wait()
    try {   
       axios({
        url: `https://vikit.ai:3001/upload`,
        method: "post",
        headers: {},
        data: formData,      
      }).then((res) => {   
        /*const array = res.data  
        let link =""
        array.map(dat=>{
          link = dat.file_video      
        })
        setResponse(false) 
        setDownloadLink(()=>link)*/  
        setDownload(true)
           }).catch((err) => {
        console.log(err)
        //setResponse(false)
        //setError(true)
      })
    } catch (error) {
      console.log(error)
    }}})
  } else {
    if (videoText.length > 250 && !hasPayed){
      setPaymentNeeded(true)
    } else {
    axios({
      url: `https://vikit.ai:3001/upload`,
      method: "post",
      headers: {},
      data: formData,      
    }).then((res) => {   
      /*const array = res.data  
      let link =""
      array.map(dat=>{
        link = dat.file_video      
      })
      setResponse(false) 
      setDownloadLink(()=>link)*/  
      setDownload(true)
         }).catch((err) => {
      console.log(err)
      setResponse(false)
      setError(true)
    })
  }
  }

} 
  }
  
  const downloadVideo= async ()=>{
    if(download == true){
      try { 
        await axios({
          url: `http://34.140.219.237:3001/download/${downloadLink}`,
          method: "GET",
          responseType: 'blob', // important
        }).then((response) => { 
          // console.log(response.data)   
          saveAs(response.data, downloadLink);

        })
      } catch (error) {
        console.log(error)
      } ;
    } 

    }   


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open1, setOpen1] = React.useState(false)
  const handleClickOpen1 = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };

  const [open3, setOpen3] = React.useState(false)
  const handleClickOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
    console.log("urlString is" + urlString)
  };
  const removeAudio = () => {
    setVideo(null);
    setPaymentNeeded(false)
  };

  const convertIntoAudioDuration= durationInt => {
    var minutes=""
    if (Math.floor(durationInt/60) <10){
      minutes='0'+Math.floor(durationInt/60)
    }
    else {
        minutes=Math.floor(durationInt/60)
    }
    var seconds=""
    if (durationInt%60 <10){
        seconds='0'+durationInt%60
    } else {
        seconds=durationInt%60
    }
     const returnString=minutes+":"+seconds
     return returnString
  };
  // console.log("vid: ", video)
  // console.log("Img: ", image)
  
  return (
    <div><div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">
        {{
          'Podcast': (<div>Use Generative AI for your podcast background</div>),
          'Education': (<div>Use Generative AI to create educational content</div>),
          'VideoGen': (<div>Custom AI video generators</div>)
        }[WebsiteMode]}</h1>
        {hasPayed?(<p color="green">
        <div className="gpt3__header-confirmationMessage"><h4>Thank you for your purchase. You can now upload recordings of more than 15 seconds and texts of more than 250 characters</h4></div>
        
        </p>

        ):(<div></div>)}
        <p>
        {{
          'Podcast': (<div>Upload or write a podcast to generate a background of it for posting on video platforms using Generative AI.</div>),
          'Education': (<div>Upload or write a lesson and we will use the latest Generative AI technologies to create an educational video to share.</div>),
          'VideoGen': (<div>Automate AI video production with custom generators tailored to your needs.</div>)
        }[WebsiteMode]
      }
      <br></br>
<div align="center">
       <a href="https://colab.research.google.com/drive/1SltMsYv4ExYJLSagLKsZqazmCludo8vC"><img width="40%" height="40%" src="https://colab.research.google.com/assets/colab-badge.svg"></img></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <a href="https://github.com/Vikit-ai/sdk"><img  width="35%" height="35%" src={githubLogoWhite}></img></a></div>
        </p>
     {
           open1 ? (
             <>
             <Dialog
      open={open1}
      onClose={handleClose1}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ background: "rgba(0,0,0,0.5)" }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Please provide a recording and an email to build a video"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <button className="downloadButton" onClick={()=>setOpen1(false)}>close</button>
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>  
             </>
           ) : ""
         }   

      </div>

      <div className="gpt3__header-image">
        <video width="100%" height="100%" controls >
        <source src={exampleVideo} type="video/mp4"/>
      </video>
      
      </div>
      
      </div>
      <div className="gpt3__header"><div className="gpt3__header-content" ><h1 align="center"><img  width="15%" height="15%" src={microsoftaicoinnovation}></img><img style={{"margin-left": "1em"}} width="15%" height="15%" src={stationF}></img><img  width="15%" height="15%" style={{"margin-left": "1em"}} src={incubateurHec}></img></h1></div></div>

      <div className="gpt3__header section__padding" id="try" >
        <div className="gpt3__header-content">
      <p>Try out our tech! Connect to our platform and generate videos with free credits.
      <HashLink  to="/platform/">
      <div align="center" className="gpt3__header-content__input">
        
      <button type="button">
          Go to the Vikit.ai creative AI models warehouse
        </button> 
        
        </div></HashLink>
        </p>
        {/* 
      <form >
          
          {
            !download ? ( 
              <>
              {
                response ? (  
                  <Progress type="circle" percent={progress} strokeColor='#FF4820'   />      
                 
                  
                  ) : (
                  <>
        <div className="gpt3__header-content__input"> 
          
          <p>Try out our tech ! Enter the text of the video or provide a custom audio
        { video?(<div></div>):(   <TextField
        id="standard-multiline-flexible"
        multiline
        rows={2}
        maxRows={7}
        variant="filled"
        style={{ width: '100%'}}
        onChange={onInputVideoTextChange}  
        InputProps={{
          readOnly: video?true:false,
        }}
        helperText={videoText&&!hasPayed?videoText.length + "/" + "250 characters":""}
      /> )}</p>
      
        </div>
        { videoText?(<div></div>):(
        <div className="gpt3__header-content__input">           
          <label htmlFor="videoUpload" className="upload-button">
            {videoText ? "Text mode generation" : video ? "Audio uploaded" : "Upload Audio"}
          </label>
          <input
            id="videoUpload"
            type="file"
            accept="audio/*"
            style={{ display: "none" }}
            onChange={onInputChange}            
          ></input>
     
          
           <label htmlFor="videoCapture" className="upload-button">
            {videoText ? "Text mode generation" : video ? "Audio uploaded" : "Capture audio"}
          </label>
          <input
            id="videoCapture"
            type="text"
            readOnly
            style={{ display: "none" }}
            onClick={() => setOpen3(!false)}
          ></input>
         
        </div>
        
        )}
        {video && audioDuration ? (<div><AudioPlayer
          src={audioURL}
          autoPlay={false}
          autoPlayAfterSrcChange={false}
          defaultDuration={convertIntoAudioDuration(audioDuration)}
        /> <label htmlFor="removeAudio" className="upload-button">
        Remove
      </label>
      <input
      id="removeAudio"
      type="text"
      readOnly
      style={{ display: "none" }}
      onClick={() => removeAudio()}
    ></input>
        </div>):(<div></div>)}

        <div className="gpt3__header-content__input">  
        <p>Your email to receive the video once it is generated:
          <input id="email" onChange={onInputChange1}   />
        </p>
        </div>


        

        <div className="gpt3__header-content__input">
          
 { paymentNeeded ? (   
     <>
     
     <p>{video?(<div>Your audio exceeds 15 seconds. </div>):(<div>You text exceeds 250 characters. </div>)} You can make longer videos by joining our discord channel.</p>
     <div id="discord-button">
<a href="https://discord.gg/m5HpbpSnUT" target="_blank">
  <div class="icon">
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 240">
      <path class="st0" d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
      <path class="st0" d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" />
    </svg>
  </div>
  <span>Join the Discord</span>
</a>
</div>
     
     {/*<form action="https://buy.stripe.com/aEU5nydhv7Ri42Q3ce" >
     <button type="submit" >Pay with stripe</button>
    </form>}
     </>   
          ) : (            
        
          <button type="button" onClick={handleUpload}>
          Generate a Video
        </button> 

          
          )
          }
        </div>
        

                            </>
                )
              }
            
              </>
            ) :  (
            <>
            {
              error ? (<Alert variant="filled" severity="error">
              Please try again!
            </Alert>) : (<div className="gpt3__header-confirmationMessage"><h4>Video creation ongoing, you will receive it by email to {email} once it is done. The duration of this process can vary depending on the size of your input audio, but it typically takes less than 10 minuts.</h4></div>)
            }              
            </>
            )
          }           
       
      </form>
   


      {open ? (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ background: "rgba(0,0,0,0.5)" }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Capture Image"}
            </DialogTitle>
            <DialogContent>
              <WebPicture setImage={setImage} />                
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </>
      ) : null} 

{open3 ? (
        <>
          <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ background: "rgba(0,0,0,0.5)" }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Record Audio"}
            </DialogTitle>
            <DialogContent>
              <WebCam video={video} setVideo={setVideo} open3={open3} setOpen3={setOpen3} audioDuration={audioDuration} setAudioDuration={setAudioDuration} getVideoDuration={getVideoDuration} setAudioURL={setAudioURL} setPaymentNeeded={setPaymentNeeded}/>               
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </>
      ) : null}
      */} </div>   <div className="gpt3__header-image">
   
      <iframe width="560" height="315" src="https://www.youtube.com/embed/E2toBeNUOf4?si=75HDU55ypCuRptHw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      </div>
      <div className="gpt3__header section__padding" id="developers" >
      <div className="gpt3__header-content">
      <p>Vikit.ai python SDK allows to create custom AI video generators in a few lines of code.</p>
      <br></br>

      <span class="normal-code">
      <Xwrapper>

<div class="user-input-output"> <img width="10%" id="userInputExampleCode" src={input} />User inputs</div>
<br></br>
      <Highlight
      
    theme={themes.dracula}
    code={` if (text)
      videoPrompts = PromptFactory(ml_gateway=gw).create_prompt_from_text(text)
 if (audio)
      videoPrompts = PromptFactory(ml_gateway=gw).create_prompt_from_audio(audio)
 final_video = CompositeVideo()
 for sub in videoPrompts.subtitles:
      sub_video = CompositeVideo()
      first_video = RawTextBasedVideo(sub.text).build()
      second_video = RawTextBasedVideo(sub.text).build()
      transition = SeineTransition(
        source_video=first_video,
        target_video=second_video,
      )
      sub_video.append_video(first_video).append_video(transition).append_video(second_video)
      final_video.append_video(sub_video)
 final_video.build() `}
    language="objectivec"
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre style={style} >
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({ line })}>
            {line.map((token, key) => 
              token.content != 'text' ? token.content != ' final_video' ? (<span key={key} {...getTokenProps({ token })} />) : (<span class="normal-code" id="finalVariableFinalVideo" key={key} {...getTokenProps({ token })} />) : (<span id="exampleCode" key={key} {...getTokenProps({ token })} />)
              )}
              </div>
          
        )
        )}
        <div >&nbsp;</div>
      </pre>
    )}
  </Highlight>
  <Xarrow color="white" dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 1 }} start={'finalVariableFinalVideo'} end={'userOutputExampleCode'}/>
  <Xarrow color="white" dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 1 }}  start={'userInputExampleCode'} end={'exampleCode'}/>
  <div class="user-input-output"> <img width="10%" id="userOutputExampleCode" src={video_icon} />Output videos</div>

  </Xwrapper>
  </span>
  <span class="small-code">
  <Xwrapper>

<div class="user-input-output"> <img width="10%" id="userInputExampleCodeSmall" src={input} />User inputs</div>
<br></br>
      <Highlight
      
    theme={themes.dracula}
    code={` if (text)
    videoPrompts = PromptFactory(ml_gateway=gw)
                    .create_prompt_from_text(text)
 if (audio)
    videoPrompts = PromptFactory(ml_gateway=gw)
                    .create_prompt_from_audio(audio)
 final_video = CompositeVideo()
 for sub in videoPrompts.subtitles:
    sub_video = CompositeVideo()
    first_video = RawTextBasedVideo(sub.text)
                  .build()
    second_video = RawTextBasedVideo(sub.text)
                  .build()
    transition = SeineTransition(
      source_video=first_video,
      target_video=second_video,
    )
    sub_video.append_video(first_video)
    .append_video(transition)
    .append_video(second_video)
    final_video.append_video(sub_video)
 final_video.build() `}
    language="objectivec"
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre style={style} >
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({ line })}>
            {line.map((token, key) => 
              token.content != 'text' ? token.content != ' final_video' ? (<span key={key} {...getTokenProps({ token })} />) : (<span id="finalVariableFinalVideoSmall" key={key} {...getTokenProps({ token })} />) : (<span id="exampleCodeSmall" key={key} {...getTokenProps({ token })} />)
              )}
              </div>
          
        )
        )}
      </pre>
    )}
  </Highlight>
  <Xarrow color="white" dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 1 }} start={'finalVariableFinalVideoSmall'} end={'userOutputExampleCodeSmall'}/>
  <Xarrow color="white" dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 1 }}  start={'userInputExampleCodeSmall'} end={'exampleCodeSmall'}/>
  <div class="user-input-output"> <img width="10%" id="userOutputExampleCodeSmall" src={video_icon} />Output videos</div>

  </Xwrapper>
  </span>
  <br></br>
  
      </div>
      <div className="gpt3__header-content">
      <form action="https://colab.research.google.com/drive/1SltMsYv4ExYJLSagLKsZqazmCludo8vC" >
      <img src={colab} />
       <button type="submit" >Try it in colab</button>
      
        </form>
        </div>
      </div>
      
      </div>
    
  );
};

export default Header;
